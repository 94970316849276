import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby-plugin-react-i18next";
import React from "react";
import { IPartner } from "../../types/gatsby";

export interface PartnerCardProps {
  partner: IPartner;
}

const PartnerCard: React.FC<PartnerCardProps> = ({ partner }) => {
  return (
    <Link
      to={`/our-partners/partners/${partner.alternative_id}`}
      key={partner.id}
      className="col-span-6 p-4 transition-shadow duration-300 bg-white shadow-lg cursor-pointer rounded-2xl md:col-span-3 lg:col-span-2 hover:shadow-xl"
    >
      {partner.coverImage_localImage && (
        <GatsbyImage
          image={partner.coverImage_localImage.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
          className="w-full h-48 mb-4 overflow-hidden rounded-lg"
          imgClassName="rounded-lg"
          alt={`${partner.name} Cover`}
        />
      )}
      <div className="flex items-center space-x-3">
        {partner.logo_localImage && (
          <div className="w-12 h-12 p-0.5 overflow-hidden bg-white border rounded-full flex-shrink-0">
            <GatsbyImage
              image={partner.logo_localImage.childImageSharp.gatsbyImageData}
              objectFit="contain"
              objectPosition="50% 50%"
              className="w-full h-full overflow-hidden"
              imgClassName="rounded-full"
              alt={`${partner.name} Logo`}
            />
          </div>
        )}
        <div>
          <h4>{partner.name}</h4>
          <p className="text-sm text-gray-500">{partner.tagLine}</p>
        </div>
      </div>
    </Link>
  );
};

export default PartnerCard;
