import { graphql } from "gatsby";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import PartnerCard from "../../components/cards/PartnerCard";
import RewardCard from "../../components/cards/RewardCard";
import DownloadTeaserBig from "../../components/DownloadTeaserBig";
import { useGlobalState } from "../../state/globalState";
import { IMergedReward, IPartner, IReward } from "../../types/gatsby";

export interface PartnersDiscountsBonusesProps {
  data: {
    partnerData: {
      edges: {
        node: IPartner;
      }[];
    };
    partnerDataLite: {
      edges: {
        node: IPartner;
      }[];
    };
    bonusData: {
      edges: {
        node: IReward;
      }[];
    };
    discountData: {
      edges: {
        node: IReward;
      }[];
    };
  };
}

const PartnersDiscountsBonuses: React.FC<PartnersDiscountsBonusesProps> = ({
  data: { partnerData, partnerDataLite, bonusData, discountData },
}) => {
  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "light",
      hidden: false,
    });
  }, []);

  const { t } = useTranslation(["partners"]);

  const mergedBonusData = React.useMemo(
    () =>
      bonusData.edges.reduce<IMergedReward[]>((acc, { node: bonus }) => {
        const partner = partnerDataLite.edges.find(
          ({ node: p }) => p.alternative_id === bonus.partnerId
        );
        if (partner) {
          acc.push({
            ...bonus,
            partner: {
              coreIndustry: partner.node.coreIndustry,
              logo: partner.node.logo_localImage,
              name: partner.node.name,
            },
          });
        } else {
          console.log("cannot find partner", bonus.partnerId);
        }
        return acc;
      }, []),
    [partnerData, bonusData]
  );

  const mergedDiscountData = React.useMemo(
    () =>
      discountData.edges.reduce<IMergedReward[]>((acc, { node: discount }) => {
        const partner = partnerDataLite.edges.find(
          ({ node: p }) => p.alternative_id === discount.partnerId
        );
        if (partner) {
          acc.push({
            ...discount,
            partner: {
              coreIndustry: partner.node.coreIndustry,
              logo: partner.node.logo_localImage,
              name: partner.node.name,
            },
          });
        } else {
          console.log("cannot find partner", discount.partnerId);
        }
        return acc;
      }, []),
    [partnerData, discountData]
  );

  return (
    <div>
      <section className="flex flex-col justify-center pt-32 mx-auto container-4xl">
        <div className="z-10">
          <h1 className="text-5xl md:text-6xl lg:text-6.5xl leading-none text-left text-gray-500 font-black uppercase">
            <Trans i18nKey="partners:title">
              Gut für den{" "}
              <span className="inline-block max-w-full font-black text-purple-500 break-words with-hyphens">
                Planeten.
              </span>
              <span className="font-black">&nbsp;</span>
              Gut für's
              <span className="inline-block max-w-full font-black break-words text-amber-500 with-hyphens">
                Konto.
              </span>
            </Trans>
          </h1>
        </div>
      </section>

      <div className="px-4 pb-16 container-4xl">
        <div className="prose-lg text-gray-500">
          <div>
            <p>{t("partners:description")}</p>
          </div>
        </div>
      </div>

      <div className="bg-gray-100">
        <div className="pt-16 container-7xl">
          <h2 className="text-2xl leading-8 text-center text-primary font-headline">
            {t("partners:partners")}
          </h2>
          <p className="mt-2 text-center text-gray-500">
            {t("partners:partners_short_description")}
          </p>
        </div>
        <div className="px-4 py-16 overflow-visible container-7xl">
          <div className="grid grid-cols-6 gap-6">
            {partnerData.edges.map(({ node: partner }) => (
              <PartnerCard partner={partner} key={partner.id} />
            ))}
          </div>
          <div className="pt-12 text-center">
            <Link to="/our-partners/partners" className="button-green-filled">
              {t("partners:partners_see_all")}
            </Link>
          </div>
        </div>

        <div className="pt-16 container-7xl">
          <h2 className="text-2xl leading-8 text-center text-primary font-headline">
            {t("partners:bonuses_all")}
          </h2>
          <p className="mt-2 text-center text-gray-500">
            {t("partners:bonuses_short_description")}
          </p>
        </div>
        <div className="px-4 py-16 overflow-visible container-7xl">
          <div className="grid grid-cols-6 gap-6">
            {mergedBonusData.map((bonus) => (
              <RewardCard
                reward={bonus}
                partner={bonus.partner}
                key={bonus.rewardId}
              />
            ))}
          </div>
          <div className="pt-12 text-center">
            <Link to="/our-partners/bonuses" className="button-green-filled">
              {t("partners:bonuses_see_all")}
            </Link>
          </div>
        </div>

        <div className="pt-16 container-7xl">
          <h2 className="text-2xl leading-8 text-center text-primary font-headline">
            {t("partners:discounts_all")}
          </h2>
          <p className="mt-2 text-center text-gray-500">
            {t("partners:discounts_short_description")}
          </p>
        </div>
        <div className="px-4 py-16 overflow-visible container-7xl">
          <div className="grid grid-cols-6 gap-6">
            {mergedDiscountData.map((discount) => (
              <RewardCard
                reward={discount}
                partner={discount.partner}
                key={discount.id}
              />
            ))}
          </div>
          <div className="pt-12 text-center">
            <Link to="/our-partners/discounts" className="button-green-filled">
              {t("partners:discounts_see_all")}
            </Link>
          </div>
        </div>
      </div>
      <DownloadTeaserBig />
    </div>
  );
};

export const query = graphql`
  query PartnersDiscountsBonusesQuery($language: String!, $dateNow: Date!) {
    partnerData: allPlan3TPartner(
      filter: { id: { ne: "dummy" } }
      sort: { fields: fields___weight }
      limit: 6
    ) {
      edges {
        node {
          id
          name
          coreIndustry
          alternative_id
          tagLine
          logo_localImage {
            id
            childImageSharp {
              gatsbyImageData(width: 72, quality: 90, placeholder: BLURRED)
            }
          }
          coverImage_localImage {
            id
            childImageSharp {
              gatsbyImageData(
                width: 320
                quality: 90
                placeholder: BLURRED
                blurredOptions: { width: 100 }
              )
            }
          }
        }
      }
    }
    partnerDataLite: allPlan3TPartner(filter: { id: { ne: "dummy" } }) {
      edges {
        node {
          id
          alternative_id
          logo_localImage {
            id
            childImageSharp {
              gatsbyImageData(width: 72, quality: 90, placeholder: BLURRED)
            }
          }
        }
      }
    }
    bonusData: allPlan3TReward(
      filter: {
        id: { ne: "dummy" }
        validUntil: { gt: $dateNow }
        fields: { rewardType: { eq: "bonus" } }
      }
      sort: { fields: fields___weight }
      limit: 6
    ) {
      edges {
        node {
          id
          rewardId
          partnerId
          title
          price {
            amount
          }
          localImage {
            id
            childImageSharp {
              gatsbyImageData(
                width: 320
                quality: 90
                placeholder: BLURRED
                blurredOptions: { width: 100 }
              )
            }
          }
        }
      }
    }
    discountData: allPlan3TReward(
      filter: {
        id: { ne: "dummy" }
        validUntil: { gt: $dateNow }
        fields: { rewardType: { eq: "discount" } }
      }
      sort: { fields: fields___weight }
      limit: 6
    ) {
      edges {
        node {
          id
          rewardId
          partnerId
          title
          price {
            amount
          }
          localImage {
            id
            childImageSharp {
              gatsbyImageData(
                width: 320
                quality: 90
                placeholder: BLURRED
                blurredOptions: { width: 100 }
              )
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PartnersDiscountsBonuses;
